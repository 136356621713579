/* stylelint-disable selector-class-pattern */
@use './trimble-colors' as colors;

mat-radio-group {
  --mdc-radio-state-layer-size: 32px;

  // label
  --mat-checkbox-label-text-font: 'Open Sans';
  --mat-checkbox-label-text-line-height: 1.25rem;
  --mat-checkbox-label-text-size: 0.75rem;
  --mat-checkbox-label-text-tracking: 0.016rem;
  --mat-checkbox-label-text-weight: 400;

  // ---
  --mat-radio-touch-target-display: block;

  mat-radio-button {
    .mdc-radio__background {
      height: 18px;
      width: 18px;
    }

    &.mat-mdc-radio-checked {
      .mdc-radio__outer-circle {
        border-width: 6px;
      }

      .mdc-radio__inner-circle {
        background-color: #{colors.$white};
        border: none;
      }
    }
  }
}

html {
  &.light {
    mat-radio-group {
      // disabled
      --mdc-radio-disabled-selected-icon-color: #{colors.$blue-light};
      --mdc-radio-disabled-unselected-icon-color: #{colors.$gray-4};

      // unselected
      --mdc-radio-unselected-hover-icon-color: #{colors.$gray-4};
      --mdc-radio-unselected-icon-color: #{colors.$gray-4};
      --mdc-radio-unselected-pressed-icon-color: #{colors.$gray-4};

      // selected
      --mdc-radio-selected-focus-icon-color: #{colors.$blue-light};
      --mdc-radio-selected-hover-icon-color: #{colors.$blue-light};
      --mdc-radio-selected-icon-color: #{colors.$blue-light};
      --mdc-radio-selected-pressed-icon-color: #{colors.$blue-light};

      // opacity
      --mdc-radio-disabled-selected-icon-opacity: 0.5;
      --mdc-radio-disabled-unselected-icon-opacity: 0.5;

      // ---
      --mat-checkbox-label-text-color: #{colors.$gray-8};
      --mat-radio-ripple-color: transparent;
      --mat-radio-checked-ripple-color: transparent;
      --mat-radio-disabled-label-color: #{colors.$gray-8};
    }
  }

  &.dark {
    mat-radio-group {
      // disabled
      --mdc-radio-disabled-selected-icon-color: #{colors.$highlight-blue};
      --mdc-radio-disabled-unselected-icon-color: #{colors.$gray-4};

      // unselected
      --mdc-radio-unselected-hover-icon-color: #{colors.$gray-4};
      --mdc-radio-unselected-icon-color: #{colors.$gray-4};
      --mdc-radio-unselected-pressed-icon-color: #{colors.$gray-4};

      // selected
      --mdc-radio-selected-focus-icon-color: #{colors.$highlight-blue};
      --mdc-radio-selected-hover-icon-color: #{colors.$highlight-blue};
      --mdc-radio-selected-icon-color: #{colors.$highlight-blue};
      --mdc-radio-selected-pressed-icon-color: #{colors.$highlight-blue};

      // opacity
      --mdc-radio-disabled-selected-icon-opacity: 0.5;
      --mdc-radio-disabled-unselected-icon-opacity: 0.5;

      // ---
      --mat-checkbox-label-text-color: #{colors.$gray-2};
      --mat-radio-ripple-color: transparent;
      --mat-radio-checked-ripple-color: transparent;
      --mat-radio-disabled-label-color: #{colors.$gray-2};
    }
  }
}
