@layer components {
  .modus-list {
    @apply flex flex-col w-full;
    @apply rounded-sm;
  }

  .modus-list-item {
    @apply flex justify-start items-center py-2 px-4;
    @apply border border-gray-0 border-b-0 last:border-b;
    @apply first:rounded-t-sm last:rounded-b-sm;
    @apply bg-white hover:bg-gray-0;
    @apply text-sm h-10;

    // sizes
    &-small {
      @apply text-xs h-8;
    }

    &-large {
      @apply text-base h-12;
    }

    &-prefix {
      @apply mr-2;

      md-icon {
        @apply text-base text-gray-6;
        @apply w-4 h-4;
      }

      modus-switch {
        @apply inline-block pt-1 mr-1;
      }

      modus-checkbox {
        @apply -ml-1;
      }
    }

    &-content {
      @apply flex grow cursor-default;
    }

    &-suffix {
      @apply ml-2;

      md-icon {
        @apply text-base text-gray-6;
        @apply w-4 h-4;
      }

      modus-switch {
        @apply inline-block pt-1;
      }

      modus-checkbox {
        @apply -ml-1;
      }
    }

    &-selected {
      @apply bg-blue-pale;
      @apply border-blue-pale;
    }
  }
}
