@use './trimble-colors' as colors;

table[mat-table] {
  // header
  --mat-table-header-headline-font: 'Open Sans';
  --mat-table-header-headline-line-height: 1.25rem;
  --mat-table-header-headline-size: 0.875rem;
  --mat-table-header-headline-weight: 600;
  --mat-table-header-headline-tracking: 0.006rem;

  // row
  --mat-table-row-item-label-text-font: 'Open Sans';
  --mat-table-row-item-label-text-line-height: 1.25rem;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.016rem;

  // footer
  --mat-table-footer-supporting-text-font: 'Open Sans';
  --mat-table-footer-supporting-text-line-height: 1.25rem;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.016rem;

  // sizing
  // Modus style guide recommends 48px for the header row,
  // but it is increased here to be more proportional to the item rows.
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 56px;
  --mat-table-row-item-container-height: 56px;
  --mat-table-row-item-outline-width: 1px;
}

html {
  &.light {
    table[mat-table] {
      --mat-table-background-color: #{colors.$white};
      --mat-table-header-headline-color: #{colors.$gray-8};
      --mat-table-row-item-label-text-color: #{colors.$trimble-gray};
      --mat-table-row-item-outline-color: #{colors.$gray-2};

      tr {
        &[mat-header-row] {
          background-color: #{colors.$gray-light};
        }

        &[mat-row]:hover {
          background-color: #{colors.$blue-pale};
          cursor: pointer;
        }
      }
    }
  }

  &.dark {
    table[mat-table] {
      --mat-table-background-color: #{colors.$gray-10};
      --mat-table-header-headline-color: #{colors.$white};
      --mat-table-row-item-label-text-color: #{colors.$white};
      --mat-table-row-item-outline-color: #{colors.$gray-9};

      tr {
        &[mat-header-row] {
          background-color: #{colors.$gray-8};
        }

        &[mat-row]:hover {
          background-color: #{colors.$gray-8};
          cursor: pointer;
        }
      }
    }
  }
}
