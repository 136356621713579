@layer components {
  .modus-tooltip-panel {
    & .modus-tooltip {
      @apply block relative bg-gray-9 opacity-0;
      @apply text-white text-small/[18px] whitespace-pre;
      @apply px-[8px] py-[4px] rounded-sm;
    }

    &-above .modus-tooltip {
      @apply mb-[8px];

      // ::after
      @apply after:block after:absolute after:h-0 after:w-0;
      @apply after:border-solid after:border-[6px] after:border-transparent;

      // triangle
      @apply after:border-b-0 after:border-t-gray-9;
      @apply after:left-[50%] after:-ml-[6px];
      @apply after:top-[100%];
    }

    &-below .modus-tooltip {
      @apply mt-[8px];

      // ::before
      @apply before:block before:absolute before:h-0 before:w-0;
      @apply before:border-solid before:border-[6px] before:border-transparent;

      // triangle
      @apply before:border-t-0 before:border-b-gray-9;
      @apply before:left-[50%] before:-ml-[6px];
      @apply before:-top-[6px];
    }

    &-left .modus-tooltip {
      @apply mr-[8px];

      // ::after
      @apply after:block after:absolute after:h-0 after:w-0;
      @apply after:border-solid after:border-[6px] after:border-transparent;

      // triangle
      @apply after:border-r-0 after:border-l-gray-8;
      @apply after:left-[100%];
      @apply after:top-[8px];
    }

    &-right .modus-tooltip {
      @apply ml-[8px];

      // ::before
      @apply before:block before:absolute before:h-0 before:w-0;
      @apply before:border-solid before:border-[6px] before:border-transparent;

      // triangle
      @apply before:border-l-0 before:border-r-gray-9;
      @apply before:-left-[6px];
      @apply before:top-[8px];
    }
  }

  @keyframes modus-tooltip-show {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes modus-tooltip-hide {
    0% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(0.8);
    }
  }

  .modus-tooltip-show {
    animation: modus-tooltip-show 150ms cubic-bezier(0, 0, 0.2, 1) forwards;
  }

  .modus-tooltip-hide {
    animation: modus-tooltip-hide 75ms cubic-bezier(0.4, 0, 1, 1) forwards;
  }
}
