@use './trimble-colors' as colors;

mat-form-field {
  // filled-text-field
  --mdc-filled-text-field-label-text-font: 'Open Sans';
  --mdc-filled-text-field-label-text-size: 1rem;
  --mdc-filled-text-field-label-text-tracking: 0.031rem;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;

  // outlined-text-field
  --mdc-outlined-text-field-label-text-font: 'Open Sans';
  --mdc-outlined-text-field-label-text-size: 1rem;
  --mdc-outlined-text-field-label-text-tracking: 0.031rem;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;

  // form-field
  --mat-form-field-container-text-font: 'Open Sans';
  --mat-form-field-container-text-line-height: 1.5rem;
  --mat-form-field-container-text-size: 1rem;
  --mat-form-field-container-text-tracking: 0.031rem;
  --mat-form-field-container-text-weight: 400;

  // ---
  --mat-form-field-subscript-text-font: 'Open Sans';
  --mat-form-field-subscript-text-line-height: 1rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: 0.025rem;
  --mat-form-field-subscript-text-weight: 400;

  // ---
  --mat-form-field-container-height: 32px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 2px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;

  height: var(--mat-form-field-container-height);

  .mdc-text-field {
    padding: 0 8px;
    border: 1px solid #{colors.$gray-0};
    border-bottom-color: var(--mdc-filled-text-field-active-indicator-color);
    border-bottom-left-radius: var(--mdc-filled-text-field-container-shape);
    border-bottom-right-radius: var(--mdc-filled-text-field-container-shape);

    &--focused {
      border-bottom-color: var(--mdc-filled-text-field-focus-active-indicator-color);
      border-bottom-width: var(--mdc-filled-text-field-focus-active-indicator-height);
    }
  }

  // Material targets the ::before and ::after pseudo-classes on the ripple to set the form-field inidicator,
  // which turned into a really kak idea. We suppress it and set the .mdc-text field border instead.
  .mdc-line-ripple {
    &::before {
      border: none;
    }

    &::after {
      border: none;
    }
  }
}

html {
  &.light {
    mat-form-field {
      // filled-text-field
      --mdc-filled-text-field-caret-color: #{colors.$gray-8};
      --mdc-filled-text-field-focus-active-indicator-color: #{colors.$trimble-blue};
      --mdc-filled-text-field-focus-label-text-color: #{colors.$gray-8};
      --mdc-filled-text-field-container-color: #{colors.$white};
      --mdc-filled-text-field-disabled-container-color: #{colors.$gray-light};
      --mdc-filled-text-field-label-text-color: #{colors.$gray-8};
      --mdc-filled-text-field-hover-label-text-color: #{colors.$gray-8};
      --mdc-filled-text-field-disabled-label-text-color: #{colors.$gray-8};
      --mdc-filled-text-field-input-text-color: #{colors.$gray-8};
      --mdc-filled-text-field-disabled-input-text-color: #{colors.$gray-8};
      --mdc-filled-text-field-input-text-placeholder-color: #{colors.$gray-2};

      // ---
      --mdc-filled-text-field-error-hover-label-text-color: #{colors.$gray-8};
      --mdc-filled-text-field-error-focus-label-text-color: #{colors.$gray-8};
      --mdc-filled-text-field-error-label-text-color: #{colors.$gray-8};

      // ---
      --mdc-filled-text-field-active-indicator-color: #{colors.$gray-6};
      --mdc-filled-text-field-disabled-active-indicator-color: #{colors.$gray-6};
      --mdc-filled-text-field-hover-active-indicator-color: #{colors.$gray-6};
      --mdc-filled-text-field-error-active-indicator-color: #{colors.$red};
      --mdc-filled-text-field-error-focus-active-indicator-color: #{colors.$red};
      --mdc-filled-text-field-error-hover-active-indicator-color: #{colors.$red};

      // outlined-text-field
      --mdc-outlined-text-field-caret-color: #{colors.$gray-8};
      --mdc-outlined-text-field-focus-outline-color: #{colors.$trimble-blue};
      --mdc-outlined-text-field-focus-label-text-color: #{colors.$gray-8};
      --mdc-outlined-text-field-label-text-color: #{colors.$gray-8};
      --mdc-outlined-text-field-hover-label-text-color: #{colors.$gray-8};
      --mdc-outlined-text-field-disabled-label-text-color: #{colors.$gray-8};
      --mdc-outlined-text-field-input-text-color: #{colors.$gray-8};
      --mdc-outlined-text-field-disabled-input-text-color: #{colors.$gray-8};
      --mdc-outlined-text-field-input-text-placeholder-color: #{colors.$gray-2};

      // ---
      --mdc-outlined-text-field-error-focus-label-text-color: #{colors.$gray-8};
      --mdc-outlined-text-field-error-label-text-color: #{colors.$gray-8};
      --mdc-outlined-text-field-error-hover-label-text-color: #{colors.$gray-8};

      // ---
      --mdc-outlined-text-field-outline-color: #{colors.$gray-8};
      --mdc-outlined-text-field-disabled-outline-color: #{colors.$gray-6};
      --mdc-outlined-text-field-hover-outline-color: #{colors.$gray-8};
      --mdc-outlined-text-field-error-focus-outline-color: #{colors.$red};
      --mdc-outlined-text-field-error-hover-outline-color: #{colors.$red};
      --mdc-outlined-text-field-error-outline-color: #{colors.$red};

      // form-field
      --mat-form-field-focus-select-arrow-color: #{colors.$trimble-blue};
      --mat-form-field-disabled-input-text-placeholder-color: #{colors.$gray-2};
      --mat-form-field-state-layer-color: #{colors.$white};
      --mat-form-field-error-text-color: #{colors.$red};
      --mat-form-field-select-option-text-color: #{colors.$gray-8};
      --mat-form-field-select-disabled-option-text-color: #{colors.$gray-8};
      --mat-form-field-leading-icon-color: #{colors.$gray-8};
      --mat-form-field-disabled-leading-icon-color: #{colors.$gray-8};
      --mat-form-field-trailing-icon-color: #{colors.$gray-8};
      --mat-form-field-disabled-trailing-icon-color: #{colors.$gray-8};

      // ---
      --mat-form-field-error-focus-trailing-icon-color: #{colors.$red};
      --mat-form-field-error-hover-trailing-icon-color: #{colors.$red};
      --mat-form-field-error-trailing-icon-color: #{colors.$red};

      // ---
      --mat-form-field-enabled-select-arrow-color: #{colors.$gray-8};
      --mat-form-field-disabled-select-arrow-color: #{colors.$gray-8};
      --mat-form-field-hover-state-layer-opacity: 0.08;
      --mat-form-field-focus-state-layer-opacity: 0;
    }
  }

  &.dark {
    mat-form-field {
      // filled-text-field
      --mdc-filled-text-field-caret-color: #{colors.$white};
      --mdc-filled-text-field-focus-active-indicator-color: #{colors.$highlight-blue};
      --mdc-filled-text-field-focus-label-text-color: #{colors.$white};
      --mdc-filled-text-field-container-color: #{colors.$gray-10};
      --mdc-filled-text-field-disabled-container-color: #{colors.$gray-9};
      --mdc-filled-text-field-label-text-color: #{colors.$white};
      --mdc-filled-text-field-hover-label-text-color: #{colors.$white};
      --mdc-filled-text-field-disabled-label-text-color: #{colors.$white};
      --mdc-filled-text-field-input-text-color: #{colors.$white};
      --mdc-filled-text-field-disabled-input-text-color: #{colors.$white};
      --mdc-filled-text-field-input-text-placeholder-color: #{colors.$gray-2};

      // ---
      --mdc-filled-text-field-error-hover-label-text-color: #{colors.$white};
      --mdc-filled-text-field-error-focus-label-text-color: #{colors.$white};
      --mdc-filled-text-field-error-label-text-color: #{colors.$white};

      // ---
      --mdc-filled-text-field-active-indicator-color: #{colors.$highlight-blue};
      --mdc-filled-text-field-disabled-active-indicator-color: #{colors.$gray-6};
      --mdc-filled-text-field-hover-active-indicator-color: #{colors.$highlight-blue};
      --mdc-filled-text-field-error-active-indicator-color: #{colors.$red};
      --mdc-filled-text-field-error-focus-active-indicator-color: #{colors.$red};
      --mdc-filled-text-field-error-hover-active-indicator-color: #{colors.$red};

      // outlined-text-field
      --mdc-outlined-text-field-caret-color: #{colors.$white};
      --mdc-outlined-text-field-focus-outline-color: #{colors.$highlight-blue};
      --mdc-outlined-text-field-focus-label-text-color: #{colors.$white};
      --mdc-outlined-text-field-label-text-color: #{colors.$white};
      --mdc-outlined-text-field-hover-label-text-color: #{colors.$white};
      --mdc-outlined-text-field-disabled-label-text-color: #{colors.$white};
      --mdc-outlined-text-field-input-text-color: #{colors.$white};
      --mdc-outlined-text-field-disabled-input-text-color: #{colors.$white};
      --mdc-outlined-text-field-input-text-placeholder-color: #{colors.$gray-2};

      // ---
      --mdc-outlined-text-field-error-focus-label-text-color: #{colors.$white};
      --mdc-outlined-text-field-error-label-text-color: #{colors.$white};
      --mdc-outlined-text-field-error-hover-label-text-color: #{colors.$white};

      // ---
      --mdc-outlined-text-field-outline-color: #{colors.$white};
      --mdc-outlined-text-field-disabled-outline-color: #{colors.$gray-2};
      --mdc-outlined-text-field-hover-outline-color: #{colors.$white};
      --mdc-outlined-text-field-error-focus-outline-color: #{colors.$red};
      --mdc-outlined-text-field-error-hover-outline-color: #{colors.$red};
      --mdc-outlined-text-field-error-outline-color: #{colors.$red};

      // form-field
      --mat-form-field-focus-select-arrow-color: #{colors.$highlight-blue};
      --mat-form-field-disabled-input-text-placeholder-color: #{colors.$gray-2};
      --mat-form-field-state-layer-color: #{colors.$gray-10};
      --mat-form-field-error-text-color: #{colors.$red};
      --mat-form-field-select-option-text-color: #{colors.$white};
      --mat-form-field-select-disabled-option-text-color: #{colors.$white};
      --mat-form-field-leading-icon-color: #{colors.$white};
      --mat-form-field-disabled-leading-icon-color: #{colors.$white};
      --mat-form-field-trailing-icon-color: #{colors.$white};
      --mat-form-field-disabled-trailing-icon-color: #{colors.$white};

      // ---
      --mat-form-field-error-focus-trailing-icon-color: #{colors.$red};
      --mat-form-field-error-hover-trailing-icon-color: #{colors.$red};
      --mat-form-field-error-trailing-icon-color: #{colors.$red};

      // ---
      --mat-form-field-enabled-select-arrow-color: #{colors.$white};
      --mat-form-field-disabled-select-arrow-color: #{colors.$white};
      --mat-form-field-hover-state-layer-opacity: 0.08;
      --mat-form-field-focus-state-layer-opacity: 0;
    }
  }
}
