@use './trimble-colors' as colors;

mat-list-item {
  // label
  --mdc-list-list-item-label-text-font: 'Open Sans';
  --mdc-list-list-item-label-text-line-height: 1.5rem;
  --mdc-list-list-item-label-text-size: 1rem;
  --mdc-list-list-item-label-text-tracking: 0.031rem;
  --mdc-list-list-item-label-text-weight: 400;

  // supporting text
  --mdc-list-list-item-supporting-text-font: 'Open Sans';
  --mdc-list-list-item-supporting-text-line-height: 1.25rem;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: 0.016rem;
  --mdc-list-list-item-supporting-text-weight: 400;

  // trailing supporting text
  --mdc-list-list-item-trailing-supporting-text-font: 'Open Sans';
  --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.688rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.031rem;
  --mdc-list-list-item-trailing-supporting-text-weight: 600;

  // container size & shape
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mdc-list-list-item-container-shape: 0px;

  // leading
  --mdc-list-list-item-leading-avatar-shape: 9999px;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 16px;

  // active indicator
  --mat-list-active-indicator-shape: 9999px;
}

html {
  &.light {
    mat-list-item {
      --mdc-list-list-item-container-color: transparent;
      --mdc-list-list-item-leading-avatar-color: #{colors.$trimble-gray};
      --mdc-list-list-item-disabled-state-layer-color: #{colors.$trimble-gray};
      --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
      --mdc-list-list-item-label-text-color: #{colors.$trimble-gray};
      --mdc-list-list-item-supporting-text-color: #{colors.$trimble-gray};
      --mdc-list-list-item-leading-icon-color: #{colors.$trimble-gray};
      --mdc-list-list-item-trailing-supporting-text-color: #{colors.$trimble-gray};
      --mdc-list-list-item-trailing-icon-color: #{colors.$trimble-gray};
      --mdc-list-list-item-selected-trailing-icon-color: #{colors.$trimble-gray};

      // ---
      --mdc-list-list-item-disabled-label-text-color: #{colors.$trimble-gray};
      --mdc-list-list-item-disabled-leading-icon-color: #{colors.$trimble-gray};
      --mdc-list-list-item-disabled-trailing-icon-color: #{colors.$trimble-gray};

      // ---
      --mdc-list-list-item-hover-label-text-color: #{colors.$trimble-gray};
      --mdc-list-list-item-focus-label-text-color: #{colors.$trimble-gray};
      --mdc-list-list-item-hover-state-layer-color: #{colors.$trimble-gray};
      --mdc-list-list-item-hover-state-layer-opacity: 0.08;
      --mdc-list-list-item-focus-state-layer-color: #{colors.$trimble-gray};
      --mdc-list-list-item-focus-state-layer-opacity: 0.12;

      // ---
      --mdc-list-list-item-disabled-label-text-opacity: 0.3;
      --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
      --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;

      // ---
      --mat-list-active-indicator-color: #{colors.$blue-pale};
    }
  }

  &.dark {
    mat-list-item {
      --mdc-list-list-item-container-color: transparent;
      --mdc-list-list-item-leading-avatar-color: #{colors.$gray-light};
      --mdc-list-list-item-disabled-state-layer-color: #{colors.$trimble-gray};
      --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
      --mdc-list-list-item-label-text-color: #{colors.$gray-light};
      --mdc-list-list-item-supporting-text-color: #{colors.$gray-light};
      --mdc-list-list-item-leading-icon-color: #{colors.$gray-light};
      --mdc-list-list-item-trailing-supporting-text-color: #{colors.$gray-light};
      --mdc-list-list-item-trailing-icon-color: #{colors.$gray-light};
      --mdc-list-list-item-selected-trailing-icon-color: #{colors.$gray-light};

      // ---
      --mdc-list-list-item-disabled-label-text-color: #{colors.$gray-light};
      --mdc-list-list-item-disabled-leading-icon-color: #{colors.$gray-light};
      --mdc-list-list-item-disabled-trailing-icon-color: #{colors.$gray-light};

      // ---
      --mdc-list-list-item-hover-label-text-color: #{colors.$gray-light};
      --mdc-list-list-item-focus-label-text-color: #{colors.$gray-light};
      --mdc-list-list-item-hover-state-layer-color: #{colors.$gray-light};
      --mdc-list-list-item-hover-state-layer-opacity: 0.08;
      --mdc-list-list-item-focus-state-layer-color: #{colors.$gray-light};
      --mdc-list-list-item-focus-state-layer-opacity: 0.12;

      // ---
      --mdc-list-list-item-disabled-label-text-opacity: 0.3;
      --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
      --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;

      // ---
      --mat-list-active-indicator-color: #1a4c67;
    }
  }
}
