@use './trimble-colors' as colors;

mat-spinner,
mat-progress-spinner {
  --mdc-circular-progress-active-indicator-width: 2px;
  --mdc-circular-progress-size: 32px;
}

html {
  &.light {
    mat-spinner,
    mat-progress-spinner {
      &,
      &.mds-primary {
        --mdc-circular-progress-active-indicator-color: #{colors.$trimble-blue};
      }

      &.mds-secondary {
        --mdc-circular-progress-active-indicator-color: #{colors.$gray-6};
      }

      &.mds-tertiary {
        --mdc-circular-progress-active-indicator-color: #{colors.$gray-1};
      }
    }

    &.dark {
      mat-spinner,
      mat-progress-spinner {
        &,
        &.mds-primary {
          --mdc-circular-progress-active-indicator-color: #{colors.$highlight-blue};
        }

        &.mds-secondary {
          --mdc-circular-progress-active-indicator-color: #{colors.$gray-light};
        }

        &.mds-tertiary {
          --mdc-circular-progress-active-indicator-color: #{colors.$gray-4};
        }
      }
    }
  }
}
