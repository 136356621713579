@use './../../../styles/trimble-colors' as colors;
@use './../../../styles/trimble-typography' as typo;

html.light {
  sd-app-bar {
    mat-toolbar {
      --mat-toolbar-container-background-color: #{colors.$white};
      --mat-toolbar-container-text-color: #{colors.$trimble-gray};
      --mat-toolbar-standard-height: 64px;
    }
  }
}
