@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import './mat-theme.scss';
@import '../../../../libs/modus/src/modus.scss';

html,
body {
  height: 100%;
}

@layer utilities {
  // TODO: remove once tailwind supports break-anywhere
  // https://github.com/tailwindlabs/tailwindcss/pull/12128
  .break-anywhere {
    @supports (overflow-wrap: anywhere) {
      overflow-wrap: anywhere;
    }

    @supports not (overflow-wrap: anywhere) {
      word-break: break-word;
    }
  }
}
