@use './trimble-colors' as colors;

mat-dialog-container {
  --mdc-dialog-supporting-text-color: #{colors.$gray-8};
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-line-height: 1.25rem;
  --mat-dialog-with-actions-content-padding: 1rem 1rem;
  --mat-dialog-content-padding: 1rem;
  --mat-dialog-actions-padding: 1rem;
  --mat-dialog-actions-alignment: flex-end;

  .mat-mdc-dialog-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0.75rem 1rem;
    height: fit-content;
  }

  .mat-mdc-dialog-content {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #{colors.$gray-1};
  }

  .mat-mdc-dialog-actions {
    height: fit-content;
    border-top: 1px solid #{colors.$gray-1};
  }
}
