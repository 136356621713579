@use './trimble-colors' as colors;

mat-progress-bar {
  --mdc-linear-progress-active-indicator-height: 16px;
  --mdc-linear-progress-track-height: 16px;
  --mdc-linear-progress-track-shape: 0px;

  &.mds-small {
    --mdc-linear-progress-active-indicator-height: 8px;
    --mdc-linear-progress-track-height: 8px;
  }

  &.mds-x-small {
    --mdc-linear-progress-active-indicator-height: 4px;
    --mdc-linear-progress-track-height: 4px;
  }
}

html {
  &.light {
    mat-progress-bar {
      --mdc-linear-progress-active-indicator-color: #{colors.$trimble-blue};
      --mdc-linear-progress-track-color: transparent;
    }
  }

  &.dark {
    mat-progress-bar {
      --mdc-linear-progress-active-indicator-color: #{colors.$highlight-blue};
      --mdc-linear-progress-track-color: transparent;
    }
  }
}
