@layer components {
  .modus-flat-button {
    &-primary {
      @apply text-white;
      @apply bg-primary;
      @apply border-primary;

      &:enabled:hover {
        background-color: #0082d6;
        border-color: #0082d6;
      }

      &:enabled:active {
        background-color: #00548a;
        border-color: #00548a;
      }
    }

    &-secondary {
      @apply text-white;
      @apply bg-secondary;
      @apply border-secondary;

      &:enabled:hover {
        background-color: #838793;
        border-color: #838793;
      }

      &:enabled:active {
        background-color: #5e626b;
        border-color: #5e626b;
      }
    }

    &-tertiary {
      @apply text-trimble-gray;
      @apply bg-tertiary;
      @apply border-tertiary;

      &:enabled:hover {
        background-color: #e8e8ec;
        border-color: #e8e8ec;
      }

      &:enabled:active {
        @apply text-white;

        background-color: #bdbfcb;
        border-color: #bdbfcb;
      }
    }

    &-danger {
      @apply text-white;
      @apply bg-danger;
      @apply border-danger;

      &:enabled:hover {
        background-color: #e44a53;
        border-color: #e44a53;
      }

      &:enabled:active {
        background-color: #c41e28;
        border-color: #c41e28;
      }
    }
  }
}
