// https://modus.trimble.com/foundations/color-palette/
$trimble-blue: #0063a3;
$trimble-blue-dark: #004f83;
$trimble-yellow: #fbad26;
$trimble-gray: #252a2e;

// blue progression
$blue-dark: #0e416c; // not the same as Trimble Blue Dark
$blue-light: #217cbb;
$blue-pale: #dcedf9;

// neutral progression
$gray-10: #171c1e;
$gray-9: #353a40;
$gray-8: #464b52;
$gray-7: #585c65;
$gray-6: #6a6e79;
$gray-5: #7d808d;
$gray-4: #90939f;
$gray-3: #a3a6b1;
$gray-2: #b7b9c3;
$gray-1: #cbcdd6;
$gray-0: #e0e1e9;
$gray-light: #f1f1f6;
$white: #fff;

// yellow progression
$yellow-dark: #e49325;
$yellow-light: #fec157;
$yellow-pale: #fff5e4;

// red progression
$red: #da212c;
$red-dark: #e49325;
$red-light: #e86363;
$red-pale: #fbd4d7;

// green progression
$green: #1e8a44;
$green-dark: #006638;
$green-light: #4ea646;
$green-pale: #e0eccf;

// special cases
$highlight-blue: #019aeb; // Replaces Trimble Blue in Dark Mode Only

// palette
$primary: $trimble-blue;
$secondary: $gray-6;
$tertiary: $gray-1;
$warning: $yellow-dark;
$danger: $red;
