@import './basic-button/basic-button.component';
@import './flat-button/flat-button.component';
@import './icon-button/icon-button.component';
@import './stroked-button/stroked-button.component';

@layer components {
  .modus-button {
    @apply inline-flex justify-center items-center;
    @apply border border-solid rounded-sm;
    @apply font-sans font-semibold;
    @apply disabled:opacity-30;
    @apply p-0 relative select-none whitespace-nowrap hover:cursor-pointer;

    &-small {
      @apply text-xs h-6 px-2 py-0;
    }

    &-medium {
      @apply text-sm h-8 px-4 py-0;
    }

    &-large {
      @apply text-base h-12 px-6 py-0;
    }
  }
}
