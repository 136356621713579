@use './trimble-colors' as colors;

mat-drawer-container,
mat-sidenav-container {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-width: 331px;
}

html {
  &.light {
    mat-drawer-container,
    mat-sidenav-container {
      --mat-sidenav-container-background-color: #{colors.$white};
      --mat-sidenav-container-text-color: #{colors.$trimble-gray};
      --mat-sidenav-content-background-color: #{colors.$gray-0};
      --mat-sidenav-content-text-color: #{colors.$trimble-gray};
      --mat-sidenav-scrim-color: #{colors.$gray-0};
      --mat-sidenav-container-elevation-shadow: none;
      --mat-sidenav-container-divider-color: #{colors.$gray-0};
    }

    &.dark {
      mat-drawer-container,
      mat-sidenav-container {
        --mat-sidenav-container-background-color: #{colors.$gray-10};
        --mat-sidenav-container-text-color: #{colors.$white};
        --mat-sidenav-content-background-color: #{colors.$gray-10};
        --mat-sidenav-content-text-color: #{colors.$white};
        --mat-sidenav-scrim-color: #{colors.$gray-10};
        --mat-sidenav-container-elevation-shadow: none;
        --mat-sidenav-container-divider-color: #{colors.$gray-10};
      }
    }
  }
}
