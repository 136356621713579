// https://material.angular.io/guide/material-3

@use '@angular/material' as mat;

// Plus imports for other components in your app.
@use './mat-core.component-theme';
@use './mat-dialog.component-theme';
@use './mat-expansion-panel.component-theme';
@use './mat-form-field.component-theme';
@use './mat-list.component-theme';
@use './mat-progress-bar.component-theme';
@use './mat-progress-spinner.component-theme';
@use './mat-radio.component-theme';
@use './mat-select.component-theme';
@use './mat-sidenav.component-theme';
@use './mat-slider.component-theme';
@use './mat-tab.component-theme';
@use './mat-table.component-theme';
@use './mat-toolbar.component-theme';

// Custom component themes
@use '../app/hosts/app-host/app-host.component-theme';
@use '../app/hosts/app-bar/app-bar.component-theme';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core;

// Picked a color palette that stands out, so we can see where the Modus colors need to be applied.
$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$red-palette,
    ),
    typography: (
      brand-family: 'Open Sans',
      plain-family: 'Open Sans',
      regular-weight: 400,
      medium-weight: 600,
      bold-weight: 700,
    ),
  )
);

// Apply the base theme at the root, so it will be inherited by the whole app.
html {
  @include mat.core-theme($light-theme);
  @include mat.form-field-theme($light-theme);
  @include mat.list-theme($light-theme);
  @include mat.menu-theme($light-theme);
  @include mat.progress-bar-theme($light-theme);
  @include mat.progress-spinner-theme($light-theme);
  @include mat.radio-theme($light-theme);
  @include mat.select-theme($light-theme);
  @include mat.sidenav-theme($light-theme);
  @include mat.slider-theme($light-theme);
  @include mat.table-theme($light-theme);
  @include mat.tabs-theme($light-theme);
  @include mat.toolbar-theme($light-theme);
}
