@use './../../../styles/trimble-colors' as colors;
@use './../../../styles/trimble-typography' as typo;

sd-app-host {
  mat-sidenav {
    --mat-sidenav-container-shape: 0;
    --mat-sidenav-container-elevation-shadow: 0 0 4px #252a2e4d;
    --mat-sidenav-container-width: 256px;
    --mat-sidenav-container-divider-color: transparent;

    .mat-mdc-action-list {
      mat-list-item {
        --mdc-list-list-item-label-text-color: #{colors.$white};
        --mdc-list-list-item-label-text-size: #{typo.$body-size};
        --mdc-list-list-item-hover-label-text-color: #{colors.$white};

        &.mdc-list-item {
          padding-left: 20px;
          padding-right: 20px;
        }

        // Material has tokens for a hover state overlay:
        // --mdc-list-list-item-hover-state-layer-color
        // --mdc-list-list-item-hover-state-layer-opacity

        // They don't help us here, as the overlay hides the text at opacity 1,
        // and other values for opacity changes the hover color.
        &:hover {
          background-color: colors.$trimble-blue;
        }
      }
    }
  }
}

html {
  &.light {
    sd-app-host {
      mat-sidenav {
        --mat-sidenav-container-background-color: #{colors.$blue-dark};
        --mat-sidenav-container-text-color: #{colors.$white};
        --mat-sidenav-content-background-color: #{colors.$blue-dark};
        --mat-sidenav-content-text-color: #{colors.$trimble-gray};
        --mat-sidenav-scrim-color: #{colors.$blue-dark};
      }
    }
  }

  &.dark {
    sd-app-host {
      mat-sidenav {
        --mat-sidenav-container-background-color: #{colors.$gray-10};
        --mat-sidenav-container-text-color: #{colors.$white};
        --mat-sidenav-content-background-color: #{colors.$trimble-gray};
        --mat-sidenav-content-text-color: #{colors.$white};
        --mat-sidenav-scrim-color: #{colors.$gray-10};
      }
    }
  }
}
