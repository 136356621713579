@layer components {
  .modus-autocomplete {
    // placeholder
  }

  .modus-autocomplete-panel {
    .modus-option-list {
      @apply w-full bg-white border border-gray-0;
      @apply border-t-2 border-t-blue-light;
      @apply shadow-[0_0_4px_0_rgba(36,35,45,0.3)];
      @apply overflow-y-auto;

      .modus-option {
        @apply flex justify-start items-center min-h-[2rem] text-xs pl-4 pr-2;
        @apply border border-transparent border-b-gray-0;
        @apply last-of-type:border-b-0;
        @apply hover:bg-gray-0;

        &-text {
          @apply w-full;
        }

        &-active {
          @apply bg-gray-0;
        }

        &-selected {
          // placeholder
        }

        .modus-icons {
          @apply text-base;
        }
      }
    }
  }
}
