@layer components {
  input.modus-input,
  textarea.modus-input {
    @apply text-xs text-trimble-gray h-8 w-full px-2;
    @apply border border-solid border-gray-0 rounded-sm border-b-trimble-gray;

    // focus
    @apply focus-visible:outline-none;
    @apply focus:border-b-blue-light;
    @apply focus:shadow-[0px_1px_0px_0px] focus:shadow-blue-light;

    // valid
    &.ng-dirty.ng-valid {
      @apply border-b-green-dark;
      @apply shadow-[0px_1px_0px_0px] shadow-green-dark;
    }

    // invalid
    &.ng-touched.ng-invalid {
      @apply border-b-danger;
      @apply shadow-[0px_1px_0px_0px] shadow-danger;
    }

    // NB! read-only MUST preceed disabled.
    // Disabled inputs have both :read-only AND :disabled pseudo-classes applied,
    // so we need :disabled to override :read-only for those cases.

    // read-only
    @apply read-only:bg-gray-0 read-only:border-b-gray-6;

    // disabled
    @apply disabled:opacity-70 disabled:bg-gray-1 disabled:bg-opacity-40 disabled:border-b-gray-6;
  }

  input.modus-input {
    &.modus-control-size-large {
      @apply text-sm h-12;
    }
  }

  textarea.modus-input {
    @apply h-auto py-[0.375rem] leading-4;

    &.modus-control-size-large {
      @apply text-sm leading-4;
    }
  }
}
