@layer components {
  .modus-basic-button {
    @apply text-primary;
    @apply fill-primary;
    @apply bg-transparent;
    @apply border-transparent;

    &:enabled:hover {
      @apply text-primary;

      background: rgb(0 99 163 / 20%);
    }

    &:enabled:active {
      color: #004470;
      background: rgb(0 99 163 / 30%);
    }
  }
}
