@use './trimble-colors' as colors;

mat-toolbar {
  --mat-toolbar-title-text-font: 'Open Sans';
  --mat-toolbar-title-text-line-height: 1.5rem;
  --mat-toolbar-title-text-size: 1.25rem;
  --mat-toolbar-title-text-tracking: 0rem;
  --mat-toolbar-title-text-weight: 500;
  --mat-toolbar-standard-height: 56px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  &.light {
    mat-toolbar {
      --mat-toolbar-container-background-color: #{colors.$gray-light};
      --mat-toolbar-container-text-color: #{colors.$gray-8};
    }
  }

  &.dark {
    mat-toolbar {
      --mat-toolbar-container-background-color: #{colors.$gray-10};
      --mat-toolbar-container-text-color: #{colors.$white};
    }
  }
}
