@layer components {
  .modus-stroked-button {
    &-primary {
      @apply text-primary;
      @apply bg-transparent;
      @apply border-primary;

      &:enabled:hover {
        background-color: rgb(0 99 163 / 15%);

        @apply border-primary;
      }

      &:enabled:active {
        color: #00548a;
        background-color: rgb(0 99 163 / 20%);
        border-color: #00548a;
      }
    }

    &-secondary {
      @apply text-secondary;
      @apply bg-transparent;
      @apply border-secondary;

      &:enabled:hover {
        color: #354551;
        background-color: rgb(33 42 50 / 15%);
        border-color: #354551;
      }

      &:enabled:active {
        color: #1a1d20;
        background-color: rgb(33 42 50 / 20%);
        border-color: #1a1d20;
      }
    }
  }
}
