@use '@angular/material' as mat;
@use './trimble-colors' as colors;

mat-tab-group,
[mat-tab-nav-bar] {
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 3px;
  --mdc-tab-indicator-active-indicator-shape: 0;

  // header-label
  --mat-tab-header-label-text-font: 'Open Sans';
  --mat-tab-header-label-text-size: 0.875rem;
  --mat-tab-header-label-text-tracking: 0.006rem;
  --mat-tab-header-label-text-line-height: 1.25rem;
  --mat-tab-header-label-text-weight: 600;

  // ---
  --mat-tab-header-divider-height: 0;

  .mdc-tab {
    padding: 0 32px;
    border-top: 1px solid transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-transform: uppercase;
  }

  &.mds-small {
    @include mat.tabs-density(-3);

    --mat-tab-header-label-text-size: 0.75rem;
  }

  .mat-mdc-tab-body-wrapper {
    flex-grow: 1;
  }

  mat-tab-body {
    z-index: auto;
  }
}

html {
  &.light {
    mat-tab-group,
    [mat-tab-nav-bar] {
      --mdc-tab-indicator-active-indicator-color: #{colors.$blue-light};
      --mat-tab-header-divider-color: #{colors.$gray-1};
      --mat-tab-header-pagination-icon-color: #{colors.$trimble-gray};
      --mat-tab-header-inactive-label-text-color: #{colors.$trimble-gray};
      --mat-tab-header-active-label-text-color: #{colors.$blue-light};
      --mat-tab-header-active-ripple-color: #{colors.$blue-pale};
      --mat-tab-header-inactive-ripple-color: #{colors.$gray-0};
      --mat-tab-header-inactive-focus-label-text-color: #{colors.$trimble-gray};
      --mat-tab-header-inactive-hover-label-text-color: #{colors.$trimble-gray};
      --mat-tab-header-active-focus-label-text-color: #{colors.$blue-light};
      --mat-tab-header-active-hover-label-text-color: #{colors.$blue-light};
      --mat-tab-header-active-focus-indicator-color: #{colors.$blue-light};
      --mat-tab-header-active-hover-indicator-color: #{colors.$blue-light};

      .mdc-tab:hover {
        background-color: #{colors.$blue-pale};
      }
    }
  }

  &.dark {
    mat-tab-group,
    [mat-tab-nav-bar] {
      --mdc-tab-indicator-active-indicator-color: #0c77be;
      --mat-tab-header-divider-color: #{colors.$gray-1};
      --mat-tab-header-pagination-icon-color: #{colors.$white};
      --mat-tab-header-inactive-label-text-color: #{colors.$white};
      --mat-tab-header-active-label-text-color: #0c77be;
      --mat-tab-header-active-ripple-color: #0c77be;
      --mat-tab-header-inactive-ripple-color: #{colors.$white};
      --mat-tab-header-inactive-focus-label-text-color: #{colors.$white};
      --mat-tab-header-inactive-hover-label-text-color: #{colors.$white};
      --mat-tab-header-active-focus-label-text-color: #0c77be;
      --mat-tab-header-active-hover-label-text-color: #0c77be;
      --mat-tab-header-active-focus-indicator-color: #0c77be;
      --mat-tab-header-active-hover-indicator-color: #0c77be;

      /* stylelint-disable-next-line no-descending-specificity */
      .mdc-tab:hover {
        background-color: #{colors.$gray-8};
      }
    }
  }
}
