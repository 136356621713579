@layer components {
  .modus-menu-panel {
    @apply min-w-max;

    .modus-menu {
      @apply flex flex-col min-w-min;
      @apply rounded-sm;

      .modus-menu-item {
        @apply flex justify-start items-center w-full h-10;
        @apply border border-gray-0 border-b-0 last:border-b;
        @apply first:rounded-t-sm last:rounded-b-sm;
        @apply bg-white hover:bg-gray-0;
        @apply text-sm cursor-pointer;

        &-content {
          @apply flex grow py-2 px-4;
        }

        &-disabled {
          @apply pointer-events-none;
          @apply text-gray-1;
        }
      }
    }
  }
}
