/* stylelint-disable selector-class-pattern */
@use './trimble-colors' as colors;

mat-select {
  --mat-select-trigger-text-font: 'Open Sans';
  --mat-select-trigger-text-tracking: 0.031rem;
  --mat-select-trigger-text-weight: 400;
  --mat-select-arrow-transform: translateY(-8px);
  --mat-select-container-elevation-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

  &.modus-dropdown {
    --mat-select-trigger-text-size: 14px;
    --mat-select-trigger-text-line-height: 20px;
  }

  &.modus-multi-select {
    --mat-select-trigger-text-size: 12px;
    --mat-select-trigger-text-line-height: 16px;
  }
}

// Scoping to the panelClass is not specific enough, have to include .mat-mdc-select-panel.
.mat-mdc-select-panel {
  --mat-select-panel-background-color: #{colors.$white};

  &.modus-dropdown-panel,
  &.modus-multi-select-panel {
    padding: 0;

    mat-option {
      height: 32px;
      min-height: 32px;

      &:not(:last-of-type) {
        border-bottom: 1px solid #{colors.$gray-0};
      }

      // Have to be more specific to set the selected and hover background colors.
      &.mat-mdc-option {
        &.mdc-list-item--selected {
          background-color: #{colors.$blue-pale};

          &:not(.mdc-list-item--disabled):hover {
            background-color: var(--mat-option-hover-state-layer-color);
          }
        }
      }
    }
  }

  &.modus-dropdown-panel {
    border: 1px solid #{colors.$gray-0};
  }

  &.modus-multi-select-panel {
    // Material targets .cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel,
    // and sets the top border radius to zero. To overcome this, we must be more specific than (0,3,1).
    border-radius: 4px !important;

    mat-pseudo-checkbox {
      border-radius: 4px;
    }
  }
}

html {
  &.light {
    mat-select {
      --mat-select-panel-background-color: #{colors.$white};
      --mat-select-enabled-trigger-text-color: #{colors.$trimble-gray};
      --mat-select-disabled-trigger-text-color: #{colors.$gray-8};
      --mat-select-placeholder-text-color: #{colors.$gray-0};
      --mat-select-enabled-arrow-color: #{colors.$trimble-gray};
      --mat-select-disabled-arrow-color: #{colors.$gray-8};
      --mat-select-focused-arrow-color: #{colors.$trimble-blue};
      --mat-select-invalid-arrow-color: #{colors.$red};
    }
  }

  &.dark {
    mat-select {
      --mat-select-panel-background-color: #{colors.$trimble-gray};
      --mat-select-enabled-trigger-text-color: #{colors.$white};
      --mat-select-disabled-trigger-text-color: #{colors.$gray-4};
      --mat-select-placeholder-text-color: #{colors.$gray-2};
      --mat-select-enabled-arrow-color: #{colors.$white};
      --mat-select-disabled-arrow-color: #{colors.$gray-4};
      --mat-select-focused-arrow-color: #{colors.$highlight-blue};
      --mat-select-invalid-arrow-color: #{colors.$red};
    }
  }
}
