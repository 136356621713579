@layer components {
  .modus-form-field {
    @apply flex flex-col;

    .modus-form-field-label {
      @apply py-[.25rem];
      @apply text-xs text-gray-8;

      .modus-form-field-required-marker {
        @apply before:content-['*'];
        @apply before:text-red before:text-xs;
        @apply before:px-1;
      }
    }

    .modus-form-field-control {
      // reset controls when inside a modus-form-field-control
      input.modus-input,
      select.modus-select,
      textarea.modus-input {
        @apply h-[30px];
        @apply border-none rounded-none;

        // focus
        @apply focus:border-none focus:shadow-none;

        // valid
        &.ng-dirty.ng-valid {
          @apply border-none shadow-none;
        }

        // invalid
        &.ng-touched.ng-invalid {
          @apply border-none shadow-none;
        }
      }

      input.modus-input {
        &.modus-control-size-large {
          @apply text-sm h-[46px];
        }
      }

      select.modus-select {
        &.modus-control-size-large {
          @apply text-sm h-[46px];
        }
      }

      textarea.modus-input {
        @apply h-auto py-[0.375rem] leading-4;

        &.modus-control-size-large {
          @apply text-sm leading-4;
        }
      }

      @apply flex;
      @apply border border-solid border-gray-0 rounded-sm;
      @apply border-b-trimble-gray;
      @apply focus-visible:outline-none;

      .modus-form-field-icon-prefix,
      .modus-form-field-icon-suffix {
        @apply flex py-[3px] bg-white;

        md-icon {
          @apply flex items-center text-base/4 text-gray-8;
        }
      }

      .modus-form-field-text-prefix,
      .modus-form-field-text-suffix {
        @apply h-[30px] py-2 bg-white align-middle text-sm/4 text-gray-8;
      }

      .modus-form-field-icon-prefix,
      .modus-form-field-text-prefix {
        @apply pl-2;
      }

      .modus-form-field-icon-suffix,
      .modus-form-field-text-suffix {
        @apply pr-2;
      }

      // focused
      &.modus-focused {
        @apply border-b-blue-light;
        @apply shadow-[0px_1px_0px_0px] shadow-blue-light;
      }

      // valid
      &.modus-dirty.modus-valid {
        @apply border-b-green-dark;
        @apply shadow-[0px_1px_0px_0px] shadow-green-dark;
      }

      // invalid
      &.modus-touched.modus-invalid {
        @apply border-b-danger;
        @apply shadow-[0px_1px_0px_0px] shadow-danger;
      }

      // disabled
      &.modus-disabled {
        .modus-form-field-icon-prefix,
        .modus-form-field-icon-suffix,
        .modus-form-field-text-prefix,
        .modus-form-field-text-suffix {
          @apply opacity-70 bg-gray-1 bg-opacity-40 border-b-gray-6;
        }
      }

      // readonly
      &.modus-readonly {
        .modus-form-field-icon-prefix,
        .modus-form-field-icon-suffix,
        .modus-form-field-text-prefix,
        .modus-form-field-text-suffix {
          @apply bg-gray-0 border-b-gray-6;
        }
      }
    }

    .modus-form-field-subscript {
      @apply py-[.25rem] text-xs text-gray-7;

      & modus-hint {
        @apply text-gray-7;
      }

      & modus-valid {
        @apply text-green-dark;
      }

      & modus-error {
        @apply text-danger;
      }
    }
  }
}
