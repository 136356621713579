@layer components {
  .modus-icon-button {
    @apply text-trimble-gray;
    @apply bg-transparent;
    @apply border-transparent;
    @apply h-8 w-8 p-0;

    &:enabled:hover {
      color: #3c444a;
      background-color: rgb(37 42 46 / 20%);
      border-color: transparent;
    }

    &:enabled:active {
      color: #0e1012;
      background-color: rgb(37 42 46 / 30%);
      border-color: transparent;
    }
  }
}
