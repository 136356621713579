mat-expansion-panel {
  .mat-expansion-panel-content {
    height: 100%;

    .mat-expansion-panel-body {
      padding: 0;
      height: 100%;
    }
  }
}
