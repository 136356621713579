@layer components {
  select.modus-select {
    @apply appearance-none;
    @apply text-xs h-8 w-full pl-3 pr-7;
    @apply border border-solid border-gray-0 rounded-sm border-b-trimble-gray;

    // focus
    @apply focus-visible:outline-none focus:border-b-2 focus:border-b-blue-light;

    // valid
    &.ng-dirty.ng-valid {
      @apply border-b-2 border-b-green-dark;
    }

    // invalid
    &.ng-touched.ng-invalid {
      @apply border-b-2 border-b-danger;
    }

    // disabled
    @apply disabled:opacity-70 disabled:bg-gray-1 disabled:bg-opacity-40 disabled:border-b-gray-6;

    &.modus-control-size-large {
      @apply text-sm h-12;
    }

    // arrow
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="%236a6e79" class="mi-solid mi-expand-more" viewBox="0 0 24 24"><path d="M15.88 9.29 12 13.17 8.12 9.29a.996.996 0 1 0-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 0 0 0-1.41c-.39-.38-1.03-.39-1.42 0Z"/></svg>');
    background-repeat: no-repeat, repeat;
    background-position: right 0.125rem top 50%;
    background-size: 24px 24px;
  }
}
